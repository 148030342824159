import { RunningLine } from 'components/RunningLine'

import { HelmetFunc } from 'components/PageMetaData'

import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { backgroundColors, colors } from 'styles/colors'
import { displayWidth } from 'styles/width'
import { useTranslation } from 'react-i18next'
import { sendEvent, gtag } from 'tracking'

import { Header } from 'blocks/Header/Header'
import { graphql } from 'gatsby'
import { usePagePath } from 'hooks/usePagePath'

import { Container } from 'components/Container'
import { indent } from 'styles/indent'
import {
    ProjectQuestionNew,
    ProjectQuestionNewDesktop,
} from 'blocks/ProjectQuestionNew'
import {
    ServicesItemPropNew,
    ServicesItemNew,
} from 'blocks/Services/ServicesItem'
import { getDataByLanguage } from 'utils/getDataByLanguage'
import { mobileAfterBorder } from 'styles/mobileAfterBorder'

import { MobileMenuPosadka } from 'components/MobileMenuPosadka'
import { PosadkaLinkBlock } from 'components/PosadkaLinkBlock'
import { CommercialProposalFormBlock } from 'blocks/CommercialProposalFormBlock'
import { ComercialForm } from 'components/form/CommercialForm'
import { LeftSidebar, RightSidebar } from 'components/SideBar'

const Desktop = styled.div`
    position: relative;
    display: none;
    width: 100%;
    height: 100%;
    @media (min-width: ${displayWidth.tablet}) {
        display: block;
    }
`
const Wrap = styled.div`
    top: 66px;
    height: calc(100vh - 126px);
    left: 0;
    right: 0;
    bottom: 60px;
    position: absolute;
    overflow: auto;
    @media (min-width: ${displayWidth.tablet}) {
        display: none;
    }
`
const WrapDesktop = styled.div`
    display: none;
    @media (min-width: ${displayWidth.tablet}) {
        display: block;
        top: 80px;
        height: calc(100vh - 80px);
        left: 0;
        right: 0;
        bottom: 0px;
        position: absolute;
        overflow: auto;
        /* background-color: ${backgroundColors.vizualization}; */
    }
`
const PromoHeroColumn = styled.div`
    :first-child {
        display: flex;
    }
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-left: 1px solid ${colors.dark};
    border-right: 1px solid ${colors.dark};
    margin-bottom: 50px;
    flex-grow: 0;
    button {
        width: 68%;
        margin: 10px auto 30px;
        height: 60px;
        background-color: ${colors.accentText};
    }
`
const ContainerStyled = styled(Container)`
    padding: 0 ${indent.mobile};
    box-sizing: border-box;
`
const ServicesListStyled = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 0 0 16px;
    ${mobileAfterBorder};
    @media (min-width: ${displayWidth.tablet}) {
        margin: 16px 0px;
    }
`
const Arrow = styled.span`
    --arrow-height: 100px;
    --arrow-indent: 5px;
    content: '';
    --arrow-bottom: calc(-1 * var(--arrow-height) - var(--arrow-indent));
    position: absolute;
    bottom: var(--arrow-bottom);
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    display: block;
    width: 0;
    height: 0;
    border-left: var(--arrow-height) solid transparent;
    border-right: var(--arrow-height) solid transparent;
    border-top: var(--arrow-height) solid ${backgroundColors.vizualization};
`

const ServiceItemWrapper = styled.div<{ marginBottom: boolean }>`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: ${({ marginBottom }) => (marginBottom ? '105px' : '5px')}; ;
`
const TitleNew = styled.h2`
    text-transform: uppercase;
    font-size: 25px;
    line-height: 35px;
    font-weight: bold;
    margin: 30px 40px 5px;
    text-align: center;
    @media (min-width: ${displayWidth.tablet}) {
        margin: 40px auto 0;
    }
`

const ContactsWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`

const pageMetadata = {
    uk: {
        title: 'Дизайн проект квартири за $99',
        description:
            "Виконаємо дизайн проект інтер'єру усієї квартири за 99 доларів США",
    },
    ru: {
        title: 'Дизайн проект квартиры за $99',
        description:
            'Выполним дизайн проект интерьера всей квартиры за 99 долларов США',
    },
    en: {
        title: 'Apartment design for $99',
        description:
            'We will design the interior project of the entire apartment for 99 US dollars',
    },
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Posadka = ({ data }: { data: any }) => {
    const { i18n, t } = useTranslation()

    const [isAnswerVisible, setIsAnswerVisible] = useState(-1)
    const { getPagePath } = usePagePath()
    const scrolled25Send = useRef(false)
    const scrolled50Send = useRef(false)
    const scrolled75Send = useRef(false)
    const scrolled100Send = useRef(false)

    const pagePath = getPagePath(i18n.language)

    // Reset scroll event when page changes
    useEffect(() => {
        scrolled25Send.current = false
        scrolled50Send.current = false
        scrolled75Send.current = false
        scrolled100Send.current = false
        gtag('config', `${process.env.GA_ID}`, {
            // eslint-disable-next-line camelcase
            page_location: document.location,
        })
    }, [pagePath])

    const onScroll = () => {
        const block = document.getElementById('blockF')
        const scrollPosition = block!.scrollTop
        const windowHeight = block!.clientHeight
        const bodyHeight = block!.scrollHeight
        const blockMod = document.getElementById('wrap')
        const scrollPositionMob = blockMod!.scrollTop
        const windowHeightMob = blockMod!.clientHeight
        const bodyHeightMob = blockMod!.scrollHeight
        setTimeout(() => {
            const trackScroll = () => {
                const scrolledRation = Math.ceil(
                    ((scrollPosition + windowHeight) / bodyHeight) * 100
                )
                if (
                    block &&
                    !scrolled100Send!.current &&
                    scrolledRation >= 100
                ) {
                    sendEvent('100', {
                        eventCategory: 'ScrollDepth',
                    })
                    scrolled100Send!.current = true
                    return
                }

                if (block && !scrolled75Send!.current && scrolledRation >= 75) {
                    sendEvent('75', {
                        eventCategory: 'ScrollDepth',
                    })
                    scrolled75Send!.current = true
                    return
                }

                if (block && !scrolled50Send!.current && scrolledRation >= 50) {
                    sendEvent('50', {
                        eventCategory: 'ScrollDepth',
                    })
                    scrolled50Send!.current = true
                    return
                }

                if (block && !scrolled25Send!.current && scrolledRation >= 25) {
                    sendEvent('25', {
                        eventCategory: 'ScrollDepth',
                    })
                    scrolled25Send!.current = true
                }
            }
            const trackScrollMob = () => {
                const scrolledRationMob = Math.ceil(
                    ((scrollPositionMob + windowHeightMob) / bodyHeightMob) *
                        100
                )
                if (
                    block &&
                    !scrolled100Send!.current &&
                    scrolledRationMob >= 100
                ) {
                    sendEvent('100', {
                        eventCategory: 'ScrollDepth',
                    })
                    scrolled100Send!.current = true
                    return
                }

                if (
                    block &&
                    !scrolled75Send!.current &&
                    scrolledRationMob >= 75
                ) {
                    sendEvent('75', {
                        eventCategory: 'ScrollDepth',
                    })
                    scrolled75Send!.current = true
                    return
                }

                if (
                    block &&
                    !scrolled50Send!.current &&
                    scrolledRationMob >= 50
                ) {
                    sendEvent('50', {
                        eventCategory: 'ScrollDepth',
                    })
                    scrolled50Send!.current = true
                    return
                }

                if (
                    block &&
                    !scrolled25Send!.current &&
                    scrolledRationMob >= 25
                ) {
                    sendEvent('25', {
                        eventCategory: 'ScrollDepth',
                    })
                    scrolled25Send!.current = true
                }
            }
            trackScrollMob()
            trackScroll()
        }, 700)
    }
    const { questions } = getDataByLanguage(
        data.allServicesNewYaml,
        i18n.language
    )
    return (
        <div>
            <HelmetFunc data={pageMetadata} />
            <Wrap id="wrap" onScroll={onScroll}>
                <MobileMenuPosadka />
                <PosadkaLinkBlock />
                <ProjectQuestionNew data={data} />

                <ServicesListStyled>
                    <TitleNew>{t('howWeWork')}</TitleNew>
                    {questions.map(
                        (item: ServicesItemPropNew, index: number) => {
                            return (
                                <ServiceItemWrapper
                                    marginBottom={questions.length > index + 1}
                                    key={index}
                                >
                                    <ServicesItemNew
                                        setIsAnswerVisible={setIsAnswerVisible}
                                        isAnswerVisible={isAnswerVisible}
                                        name={index}
                                        title={item.title}
                                        question={item.question}
                                        answer={item.answer}
                                        justText={true}
                                    />
                                    {questions.length > index + 1 && <Arrow />}
                                </ServiceItemWrapper>
                            )
                        }
                    )}
                </ServicesListStyled>
                <RunningLine inverse>{t('designProject99')}</RunningLine>
                <ContainerStyled columns={'1fr'}>
                    <PromoHeroColumn>
                        <ComercialForm placement="PosadkaMobileZa99g" />
                    </PromoHeroColumn>
                </ContainerStyled>
            </Wrap>

            <Desktop id="blockF" onScroll={onScroll}>
                <Header />

                <WrapDesktop>
                    <ProjectQuestionNewDesktop data={data} />
                    <RunningLine>{t('designProject99')}</RunningLine>
                    <ContactsWrapper>
                        <LeftSidebar background={colors.white} />
                        <Container style={{ margin: '0 auto' }}>
                            <TitleNew>{t('howWeWork')}</TitleNew>
                            <ServicesListStyled>
                                {questions.map(
                                    (
                                        item: ServicesItemPropNew,
                                        index: number
                                    ) => {
                                        return (
                                            <ServiceItemWrapper
                                                key={index}
                                                marginBottom={
                                                    questions.length > index + 1
                                                }
                                            >
                                                <ServicesItemNew
                                                    setIsAnswerVisible={
                                                        setIsAnswerVisible
                                                    }
                                                    isAnswerVisible={
                                                        isAnswerVisible
                                                    }
                                                    name={index}
                                                    title={item.title}
                                                    question={item.question}
                                                    answer={item.answer}
                                                    justText={true}
                                                />
                                                {questions.length >
                                                    index + 1 && <Arrow />}
                                            </ServiceItemWrapper>
                                        )
                                    }
                                )}
                            </ServicesListStyled>
                        </Container>
                        <RightSidebar />
                    </ContactsWrapper>
                    <RunningLine>{t('designProject99')}</RunningLine>
                    <CommercialProposalFormBlock placement="PosadkaZa99g" />
                </WrapDesktop>
            </Desktop>
        </div>
    )
}

export default Posadka

export const query = graphql`
    query {
        allServicesNewYaml {
            edges {
                node {
                    questions {
                        title
                        question
                        answer
                    }
                    parent {
                        ... on File {
                            name
                        }
                    }
                }
            }
        }
        allImageSharp {
            edges {
                node {
                    fluid(srcSetBreakpoints: [400]) {
                        originalName
                        ...GatsbyImageSharpFluid
                    }
                    parent {
                        ... on File {
                            name
                        }
                    }
                }
            }
        }
        allProjectStructureQuestionYaml {
            edges {
                node {
                    title
                    price
                    image
                    promo
                    items {
                        question
                        answer
                        svg
                    }
                    itemsNew {
                        question
                        answer
                        svg
                    }
                    parent {
                        ... on File {
                            name
                        }
                    }
                }
            }
        }
    }
`
